import React from "react";
import food from "../../images/icons/food.svg";
import bag from "../../images/icons/bag.svg";
import soap from "../../images/icons/soap.svg";
import SegmentHeader from "../common/SegmentHeader";
import SegmentFeature from "../common/SegmentFeature";

const SpheresSegment = () => (
  <div className="spheres col-xl-10 col-md-11 col-11" id={"spheres"}>
    <SegmentHeader
      label={"— сферы применения"}
      title={"Незаменимое решение для вашего бизеса!"}
    />
    <div className="spheres-list row no-gutters">
      <SegmentFeature
        img={food}
        title={"HoReCa"}
        description={`Рестораны, кафе, кальянные. Регистрируйте гостей за 1 минуту. Интегрируйте систему лояльности`}
      />
      <SegmentFeature
        img={bag}
        title={"Ритейл"}
        description={`Объединяйте каналы продаж, отслеживайте LTV клиентов и улучшайте показатели бизнеса`}
      />
      <SegmentFeature
        img={soap}
        title={"Салоны красоты"}
        description={`Предлагайте вашим клиентам электронные скидочные карты и повышайте вовлеченность в систему лояльности`}
      />
    </div>
  </div>
);

export default SpheresSegment;
