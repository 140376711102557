import React from "react";
import SegmentHeader from "../common/SegmentHeader";
import SubscriptionCard from "../common/SubscriptionCard";
import abstract_5 from "../../images/abstract/abstract_5.svg";

const SubscriptionSegment = () => (
  <>
    <div
      className="subscription col-xl-10 col-md-11 col-11"
      id={"subscriptions"}
    >
      <div className="bg abstract-5 d-none col-sm-block">
        <img src={abstract_5} alt="" />
      </div>
      <SegmentHeader
        label={"— Тарифный план"}
        title={"Кому подходит GrowCards?"}
      />
      <div className="segment-feature-description col-sm-block">
        Стоимость после подключения
      </div>

      <div className="subscription-content">
        <div className="col-xl-4 col-md-6">
          <SubscriptionCard
            title={"Начальный"}
            price={"1190 ₽"}
            duration={"/ месяц"}
            benefits={[
              "До 500 карт",
              "PUSH-уведомления",
              "Конструктор карт",
              "Распространение карт",
            ]}
          />
        </div>
        <div className="col-xl-4 col-md-6">
          <SubscriptionCard
            title={"Стандартный"}
            price={"4990 ₽"}
            duration={"/ месяц"}
            benefits={[
              "До 4000 карт",
              "Внешние интеграции",
              "Помощь в подключении",
              "Выделенный менеджер",
            ]}
          />
        </div>
        <div className="col-xl-4 col-md-6">
          <SubscriptionCard
            title={"Профессиональный"}
            price={"По запросу"}
            benefits={[
              "Больше 4000 карт",
              "Доступ к API",
              "Полное сопровождение",
              "Кастомный функционал",
            ]}
          />
        </div>
      </div>
    </div>
  </>
);

export default SubscriptionSegment;
