import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/intro";
import MainContent from "../components/main_content";
import MenuComponent from "../components/menu";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]', {
    speed: 250,
    header: ".tablet-header",
  });
}

const IndexPage = () => (
  <>
    <Layout>
      <SEO title="Электронные карты для Apple Wallet и Google Pay" />
      <div className="tablet-menu-wrapper">
        <MenuComponent />
      </div>
      <Intro />
      <MainContent />
    </Layout>
  </>
);

export default IndexPage;
