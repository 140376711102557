import React, { useState } from "react";
import logo_no_text from "../../images/growcards_logo_no_text.svg";

const TrialSegment = () => {
  const [state, setState] = useState({
    input: "",
  });

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validateAndSend = () => {
    const validEmail = re.test(String(state.input).toLowerCase());
    const errorMsg = document.querySelector(".trial .input-error");
    if (validEmail) {
      window.ym(57649267, 'reachGoal', 'reg_footer');
      window.gtag('event', 'conversion', {'send_to': 'AW-927255579/rjYfCPuhpckBEJuYk7oD'});
      errorMsg.style.display = "none";
      // window.location.href = `http://localhost:3000/register/${state.input}`;
      window.location.href = `https://app.growcards.ru/register/${state.input}`;

    } else {
      errorMsg.style.display = "block";
    }
  };

  return (
    <>
      <div className="trial col-md-12">
        <div className="trial-img">
          <img src={logo_no_text} alt="" />
        </div>
        <div className="title-big">Готовы попробовать?</div>
        <div className="title-big">
          <h3>По вопросам получения доступа к системе: Telegram <a href={"https://t.me/wavemeup"}>@wavemeup</a></h3>
        </div>

        {/*<div className="col-xl-6 col-md-7 col-11 p-0 input-container">
          <input
            type="email"
            className={"landing-input"}
            placeholder={"Введите свой email..."}
            value={state.input}
            onChange={e => setState({ ...state, input: e.target.value })}
          />
          <div className={"button-colored"} onClick={validateAndSend}>
            Получить доступ
          </div>
          <div className="input-error">Введен неверный Email</div>
        </div>*/}
      </div>
    </>
  );
};

export default TrialSegment;
