import React from "react";
import SegmentHeader from "../common/SegmentHeader";
import SegmentFeature from "../common/SegmentFeature";
import abstract_4 from "../../images/abstract/abstract_4.svg";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const YandexSegment = () => {
  const data = useStaticQuery(graphql`
    query {
      yandexImg: file(relativePath: { eq: "yandex_cloud_dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="loyalty infrastructure col-xl-10 col-md-11 col-11">
        <div className="bg abstract-4">
          <img src={abstract_4} alt="" />
        </div>
        <SegmentHeader
          label={"— инфраструктура"}
          title={"Партнерство с Yandex Cloud"}
        />

        <div className="loyalty-content row no-gutters">
          <div className="loyalty-content-feature col-xl-5 col-md-5 no-gutters">
            <SegmentFeature
              // img={upward_trend}
              title={"Надежность"}
              description={
                "Являясь официальным партнером Яндекс Облака мы построили уникальную облачную инфраструктуру и обеспечиваем своим клиентам надежный и бесперебойный доступ ко всем необходимым ресурсам."
              }
            />
          </div>

          <div className="loyalty-img col-xl-6 col-md-6 col-10">
            <Img fluid={data.yandexImg.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </>
  );
};

export default YandexSegment;
