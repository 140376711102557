import React from "react";
import "../../styles/common/subscription_card.sass";
import check from "../../images/icons/check.svg";

const SubscriptionCard = ({ title, price, duration, benefits }) => (
  <div className="subscription-card">
    <div className="subscription-card-title">{title}</div>
    <div className="subscription-card-price">
      {price}
      {duration && (
        <div className={"subscription-card-price-duration"}>{duration}</div>
      )}
    </div>
    <div className="subscription-card-benefits row">
      {benefits.map(benefit => (
        <div className={"card-benefit col-7 col-md-8"}>
          <img src={check} alt="" />
          <p>{benefit}</p>
        </div>
      ))}
    </div>
    {/*<a href="https://app.growcards.ru/register" target="_blank" className={"button-colored"}
    onClick={() => {
      window.ym(57649267, 'reachGoal', 'reg_sub');
      window.gtag('event', 'conversion', {'send_to': 'AW-927255579/rjYfCPuhpckBEJuYk7oD'});
    }}>
      Получить доступ
    </a>*/}
  </div>
);

export default SubscriptionCard;
