import React from "react";
import "../../styles/common/segment_feature.sass";

const SegmentFeature = ({ img, title, description }) => (
  <div className="segment-feature col-10">
    {img && <div className="segment-feature-img">
      <img src={img} alt="" />
    </div>}
    <div className="segment-feature-title">{title}</div>
    <div className="segment-feature-description">{description}</div>
  </div>
);

export default SegmentFeature;
