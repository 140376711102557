import React, { useState } from "react";
import "../styles/intro.sass";
import apple_icon from "../images/icons/apple_logo.svg";
import android_icon from "../images/icons/android_logo.svg";
import dot from "../images/icons/dot.svg";
import abstract_1 from "../images/abstract/abstract_1.svg";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      introImg: file(relativePath: { eq: "intro_img.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const [state, setState] = useState({
    input: "",
  });

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validateAndSend = () => {
    const validEmail = re.test(String(state.input).toLowerCase());
    const errorMsg = document.querySelector(".intro .input-error");
    if (validEmail) {
      window.ym(57649267, 'reachGoal', 'reg_header');
      window.gtag('event', 'conversion', {'send_to': 'AW-927255579/rjYfCPuhpckBEJuYk7oD'});
      errorMsg.style.display = "none";
      // window.location.href = `http://localhost:3000/register/${state.input}`;
      window.location.href = `https://app.growcards.ru/register/${state.input}`;
    } else {
      errorMsg.style.display = "block";
    }
  };

  return (
    <>
      <div className="intro col-xl-10 col-md-11 col-11 no-gutters" id={"intro"}>
        <div className="bg abstract-1 d-none d-sm-block">
          <img src={abstract_1} alt="" />
        </div>

        <div className="intro-content col-xl-8 col-md-8 col-12">
          <div className="title-big">
            Станьте ближе к вашим клиентам <img src={dot} alt="" />
          </div>

          <div className="intro-description col-11 p-0">
            Система управления и создания электронных карт.<br/>Поддержка Apple Wallet и Google Pay, интеграция с вашей CRM.
            <br/><br/>По вопросам получения доступа к системе: Telegram <a href={"https://t.me/wavemeup"}>@wavemeup</a>
          </div>

          {/*<div className="col-xl-10 col-md-11 col-12 p-0 input-container">
            <input
              type="email"
              className={"landing-input"}
              placeholder={"Введите свой email..."}
              value={state.input}
              onChange={e => setState({ ...state, input: e.target.value })}
            />
            <div className={"button-colored"} onClick={validateAndSend}>
              Получить доступ
            </div>
            <div className="input-error">Введен неверный Email</div>
          </div>*/}
        </div>

        <div className="intro-images">
          <div className="intro-images-main" style={{ width: "400xp" }}>
            <Img fluid={data.introImg.childImageSharp.fluid} />
          </div>
          <div className="intro-images-icon apple-icon">
            <img src={apple_icon} alt="" />
          </div>
          <div className="intro-images-icon android-icon">
            <img src={android_icon} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
