import React from "react";
import SegmentHeader from "../common/SegmentHeader";
import SegmentFeature from "../common/SegmentFeature";
import layout from "../../images/icons/layout.svg";
import upload from "../../images/icons/upload.svg";
import bell from "../../images/icons/bell.svg";
import check from "../../images/icons/check.svg";
import abstract_3 from "../../images/abstract/abstract_3.svg";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const FunctionSegment = () => {
  const data = useStaticQuery(graphql`
    query {
      function_part_one_img: file(
        relativePath: { eq: "function_part_one.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      function_part_two_img: file(
        relativePath: { eq: "function_part_two.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      function_part_three_img: file(
        relativePath: { eq: "function_part_three.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      function_part_one_img_uncut: file(
        relativePath: { eq: "function_part_one_uncut.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      function_part_three_img_uncut: file(
        relativePath: { eq: "function_part_three_uncut.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="function col-md-12 no-gutters col-11" id={"functions"}>
      <div className="col-xl-10 col-md-11" style={{ margin: "auto" }}>
        <SegmentHeader label={"— Принцип работы"} title={"Как это работает?"} />
      </div>

      <div className="function-part-one col-md-12 no-gutters">
        <div className="function-part-one-img col-md-6 col-10 d-none d-sm-block">
          <Img fluid={data.function_part_one_img.childImageSharp.fluid} />
        </div>
        <div className="function-part-one-img col-md-6 col-10 d-block d-sm-none">
          <Img fluid={data.function_part_one_img_uncut.childImageSharp.fluid} />
        </div>
        <div className="function-part-one-content col-md-6 p-0 no-gutters">
          <div className="col-md-8 no-gutters">
            <SegmentFeature
              img={layout}
              title={"Настройте"}
              description={
                "Система позволяет создавать и редактировать шаблоны электронных карт, выбирать отображаемые поля, настраивать цветовую гамму, загружать изображения, выбирать тип отображаемого штрих-кода."
              }
            />
            <div className="checks">
              <div className="can-do">
                <img src={check} alt="" />
                Создавайте дизайн карты
              </div>
              <div className="can-do">
                <img src={check} alt="" />
                Добавляйте гео-точки
              </div>
              <div className="can-do">
                <img src={check} alt="" />
                Создавайте удобные формы регистрации
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="function-part-two col-xl-10 col-md-11 no-gutters">
        <div className="bg abstract-3">
          <img src={abstract_3} alt="" />
        </div>
        <div className="function-part-two-content col-md-6 p-0 no-gutters">
          <div className="col-md-9 no-gutters">
            <SegmentFeature
              img={upload}
              title={"Интегрируйте"}
              description={
                "Благодаря удобному API вы можете интегрировать взаимодействие с GrowCards в вашу внутреннюю систему для использования системы лояльности и отправки уведомлений."
              }
            />
          </div>
        </div>

        <div className="function-part-two-img col-md-6 col-10">
          <Img fluid={data.function_part_two_img.childImageSharp.fluid} />
        </div>
      </div>

      <div className="function-part-three col-md-12 no-gutters">
        <div className="function-part-three-img col-md-6 col-10 d-none d-sm-block">
          <Img fluid={data.function_part_three_img.childImageSharp.fluid} />
        </div>
        <div className="function-part-three-img col-md-6 col-10 d-block d-sm-none">
          <Img
            fluid={data.function_part_three_img_uncut.childImageSharp.fluid}
          />
        </div>
        <div className="function-part-three-content col-md-6 p-0 no-gutters">
          <div className="col-md-8 no-gutters">
            <SegmentFeature
              img={bell}
              title={"Управляйте процессом"}
              description={
                "Создавайте PUSH-кампании и быстро сообщайте вашим клиентам важную информацию."
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunctionSegment;
