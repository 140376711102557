import React from "react";
import Menu from "react-burger-menu/lib/menus/slide";
import "../styles/menu.sass";
import logo from "../images/growcards_logo.svg";
import cross from "../images/icons/cross.svg";
import burger from "../images/icons/burger.svg";
import { Link } from "gatsby";

class MenuComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  showSettings(event) {
    event.preventDefault();
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
    return (
      <Menu
        disableAutoFocus
        isOpen={this.state.menuOpen}
        onStateChange={state => this.handleStateChange(state)}
        width={"100%"}
        customCrossIcon={<img src={cross} alt="close_button" />}
        customBurgerIcon={<img src={burger} alt={"toggle-menu"} />}
      >
        <div className="growcards-menu-logo">
          <img src={logo} alt="Growcards logo" />
        </div>

        <Link to={"#intro"} onClick={() => this.closeMenu()}>
          Главная
        </Link>

        <Link to={"#features"} onClick={() => this.closeMenu()}>
          Возможности карт
        </Link>

        <Link to={"#functions"} onClick={() => this.closeMenu()}>
          Принцип работы
        </Link>

        <Link to={"#spheres"} onClick={() => this.closeMenu()}>
          Сферы применения
        </Link>

        <Link to={"#subscriptions"} onClick={() => this.closeMenu()}>
          Тарифы
        </Link>

        <a href={"https://docs.growcards.ru"} onClick={() => this.closeMenu()}>
          База знаний
        </a>

        <a
          href="https://app.growcards.ru"
          target="_blank"
          onClick={() => this.closeMenu()}
        >
          Вход
        </a>
      </Menu>
    );
  }
}

export default MenuComponent;
