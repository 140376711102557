import React from "react";
import "../../styles/common/segment_header.sass";

const SegmentHeader = ({ label, title }) => (
  <div className="segment-header">
    <div className="segment-header-label">{label}</div>
    <div className="segment-header-title title-big">{title}</div>
  </div>
);

export default SegmentHeader;
