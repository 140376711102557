import React from "react";
import refresh from "../../images/icons/refresh.svg";
import bell from "../../images/icons/bell.svg";
import upload from "../../images/icons/upload.svg";
import SegmentHeader from "../common/SegmentHeader";
import SegmentFeature from "../common/SegmentFeature";
import abstract_2 from "../../images/abstract/abstract_2.svg";

const FeaturesSegment = () => (
  <div className="features col-xl-10 col-md-11 col-11" id={"features"}>
    <div className="bg abstract-2">
      <img src={abstract_2} alt="" />
    </div>
    <SegmentHeader
      label={"— Возможности карт"}
      title={"Для чего нужны карты?"}
    />
    <div className="features-list row no-gutters">
      <SegmentFeature
        img={refresh}
        title={"Динамический контент"}
        description={
          "Обновляйте данные карты в любой момент: дизайн, личная информация, бонусный счет"
        }
      />
      <SegmentFeature
        img={bell}
        title={"PUSH-уведомления"}
        description={`Маркетинговая информация, привязка к гео-локации, транзакционные уведомления`}
      />
      <SegmentFeature
        img={upload}
        title={"Интеграции"}
        description={`Подключите наш сервис к вашей внутренней системе учета и полностью раскройте потенциал электронных карт`}
      />
    </div>
  </div>
);

export default FeaturesSegment;
