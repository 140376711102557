import React from "react";
import "../styles/content.sass";
import FeaturesSegment from "./Segments/FeaturesSegment";
import FunctionSegment from "./Segments/FunctionSegment";
import LoyaltySegment from "./Segments/LoyaltySegment";
import SubscriptionSegment from "./Segments/SubscriptionSegment";
import TrialSegment from "./Segments/TrialSegment";
import SpheresSegment from "./Segments/SpheresSegment";
import YandexSegment from "./Segments/YandexSegment";

const MainContent = () => {
  return (
    <>
      <FeaturesSegment />
      <FunctionSegment />
      <LoyaltySegment />
      <SpheresSegment/>
      <SubscriptionSegment />
      <YandexSegment/>
      <TrialSegment />
    </>
  );
};

export default MainContent;
