import React from "react";
import SegmentHeader from "../common/SegmentHeader";
import SegmentFeature from "../common/SegmentFeature";
import upward_trend from "../../images/icons/upward_trend.svg";
import abstract_4 from "../../images/abstract/abstract_4.svg";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const LoyaltySegment = () => {
  const data = useStaticQuery(graphql`
    query {
      loyaltyImg: file(relativePath: { eq: "loyalty_img.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="loyalty col-xl-10 col-md-11 col-11">
        <div className="bg abstract-4">
          <img src={abstract_4} alt="" />
        </div>
        <SegmentHeader
          label={"—системы лояльности"}
          title={"Нет системы лояльности? Мы поможем!"}
        />

        <div className="loyalty-content row no-gutters">
          <div className="loyalty-content-feature col-xl-5 col-md-5 no-gutters">
            <SegmentFeature
              img={upward_trend}
              title={"Повышайте"}
              description={
                "Если у вас нет системы лояльности, то наши специалисты подберут наиболее эффективное решение, которое повысит продажи и возврат клиентов."
              }
            />
          </div>

          <div className="loyalty-img col-xl-6 col-md-6 col-10">
            <Img fluid={data.loyaltyImg.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoyaltySegment;
